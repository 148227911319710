<template>
  <div v-loading="loading">
    <!-- 添加按钮 -->
    <div class="btnStyle">
      <button class="button" @click="addInfo('add', 'info')">
        {{ $t("label.add") }}
      </button>
    </div>
    <!-- 表格 -->
    <div style="min-height: 450px">
      <el-table
        :data="tableData"
        :key="loading"
        style="width: 100%"
        border
        :empty-text="$t('label.weixin.nomore')"
      >
        <el-table-column
          v-for="(item, index) in headList"
          :key="index"
          :prop="
            item.fieldType == 'R' || item.fieldType == 'Y'
              ? item.apiname + 'ccname'
              : item.apiname
          "
        >
          <template slot="header">
            <div style="margin-right: 10px; display: inline-block">
              {{ item.fieldLabel }}
            </div>
          </template>
          <template slot-scope="scope">
            <!-- 操作列 -->
            <div v-if="item.apiname == 'OPREATE'" class="allowClick">
              <span
                style="margin-right: 10px"
                @click="addInfo('edit', scope.row)"
                >{{ $t("label.modify") }}</span
              >
              <span @click="deleteInfo(index, scope.row)">{{
                $t("label.delete")
              }}</span>
            </div>
            <!-- 复选框列 -->
            <div v-else-if="item.fieldType == 'C'">
              <!-- 1234 -->
              <el-checkbox
                v-model="scope.row[item.apiname]"
                disabled
              ></el-checkbox>
            </div>
            <!-- 人  日期 -->
            <div v-else-if="item.fieldType == 'CP'">
              <!-- 名字 -->
              {{ scope.row["createby"] }}
              <!-- 日期 -->
              /{{ scope.row["createDate"] }}
            </div>
            <div v-else-if="item.fieldType == 'UP'">
              <!-- 名字 -->
              {{ scope.row["modifyby"] }}
              <!-- 日期 -->
              /{{ scope.row["lastModifyDate"] }}
            </div>
            <!-- 不可跳转字段 -->
            <a v-else>{{ scope.row[item.apiname] }}</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页按钮 -->
    <div class="btnGroup">
      <el-button :disabled="topPage" @click="getMoreData('top')">{{
        $t("label.page.previous")
      }}</el-button>
      <el-button
        :disabled="nextPage"
        style="margin-left: 10px"
        @click="getMoreData('next')"
        >{{ $t("label.page.next") }}</el-button
      >
    </div>
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('component_setup_tabs_label_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span
        style="
          font-size: 16px;
          color: #080707;
          font-weight: bold;
          word-break: break-word;
        "
      >
        <!-- 确认删除该条数据吗？ -->
        {{ $t("label.weixin.confirm.delete") }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmDel" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  storyOrMessage,
  deleteStoryOrMessage,
  queryCarouselList,
  deleteCarousel,
} from "../api";
export default {
  props: ["viewType"],
  data() {
    return {
      tableData: [],
      headList: [],
      dialogVisible: false,
      loading: false,
      pno: 0,
      dataCount: true,
      messageId: "",
      carouselCount: 0,
    };
  },
  created() {},
  mounted() {
    // slideShow
    this.loading = true;
    this.pno = 1;
    if (this.viewType == "message") {
      this.initMessage();
    } else if (this.viewType == "slideShow") {
      this.initSlideShow();
    }
  },
  methods: {
    // 改变排序
    
    changeOrder(index) {
      index = (index - 1) / 2;
      let value = this.order[index];
      
      
      if (value == 1) {
        this.order.splice(index, 1, -1);
      } else {
        this.order.splice(index, 1, 1);
      }
      
      this.loading = true;
      this.getCarouselList();
    },
    // 轮播图列表
    async getCarouselList() {
      let obj = {
        pagenum: this.pno,
        pagesize: 10,
        ordername: "createDate",
        ordertype: `-1`,
      };
      let result = await queryCarouselList(obj);
      this.loading = false;
      this.tableData = result.data.data;
      this.carouselCount = result.data.TotalRecordSize;
      if (this.tableData.length < 10) {
        this.dataCount = false;
      } else if (this.tableData.length == 10) {
        this.dataCount = true;
      } else if (this.tableData.length == 0) {
        this.dataCount = false;
      }
      this.tableData.map((item) => {
        item.createDate = item.createDate.split(" ")[0];
        item.lastModifyDate = item.lastModifyDate.split(" ")[0];
        if (item.isactive == "0") {
          item.isactive = false;
        } else if (item.isactive == "1") {
          item.isactive = true;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 删除品牌故事
    async deleteData() {
      let obj = {
        id: this.messageId,
      };
      let result = await deleteStoryOrMessage(obj);
      if (result.result) {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label_tabpage_delsuccessz"),
          type: "success",
        });
      }
      this.loading = true;
      this.initMessage();
    },
    // 删除轮播图
    async deleCarousel() {
      let result = await deleteCarousel({ carouselid: this.messageId });
      
      if (result.result) {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label_tabpage_delsuccessz"),
          type: "success",
        });
      }
      this.loading = true;
      this.initSlideShow();
    },
    // 初始化轮播图数据的方法
    initSlideShow() {
      this.headList = [
        {
          apiname: "OPREATE",
          fieldLabel: this.$i18n.t("label.import.index.operation"), //操作
          fieldType: "opreate",
        },
        {
          apiname: "seq",
          fieldLabel: this.$i18n.t("label.newprocess5.seq"), //"顺序",
          fieldType: "seq",
        },
        {
          apiname: "carouselname",
          fieldLabel: this.$i18n.t("label.service.miniProgram.slideshowName"), //轮播图名称
          fieldType: "N",
        },
        {
          apiname: "isactive",
          fieldLabel: this.$i18n.t("label.service.miniProgram.isOpen"), //是否开启
          fieldType: "C",
        },
        {
          apiname: "createby",
          fieldLabel: this.$i18n.t("label.createby"), //"创建人",
          fieldType: "N",
        },
        {
          apiname: "createDate",
          fieldLabel: this.$i18n.t("label.quote.createdate"), //"创建日期",
          fieldType: "seq",
        },
        {
          apiname: "modifyby",
          fieldLabel: this.$i18n.t("label.lastmodifyby"), //"最后修改人",
          fieldType: "N",
        },
        {
          apiname: "createDate",
          fieldLabel: this.$i18n.t("label.service.miniProgram.lastDate"), //最后修改日期,
          fieldType: "N",
        },
      ];
      this.getCarouselList();
    },
    // 初始化消息数据
    initMessage() {
      this.headList = [
        {
          apiname: "OPREATE",
          fieldLabel: this.$i18n.t("label.import.index.operation"), //操作
          fieldType: "opreate",
        },
        {
          apiname: "_id",
          fieldLabel: this.$i18n.t("label.quickbooks.viewlist.number"), //编号
          fieldType: "S",
        },
        {
          apiname: "name",
          fieldLabel: this.$i18n.t("label.emailtocloudcc.name"), //"名称",
          fieldType: "N",
        },
        {
          apiname: "resume",
          fieldLabel: this.$i18n.t("label.service.miniProgram.intro"), //"简介",
          fieldType: "N",
        },
        {
          apiname: "isactive",
          fieldLabel: this.$i18n.t("label_enable_or_no"), //"是否启用",
          fieldType: "C",
        },
        {
          apiname: "top",
          fieldLabel: this.$i18n.t("label.service.miniProgram.isTop"),
          fieldType: "C",
        },
        {
          apiname: "type",
          fieldLabel: this.$i18n.t("label.emailtocloudcc.filed.type"), //"类型",
          fieldType: "N",
        },
      ];
      // 请求消息接口
      this.queryStoryOrMessage();
    },
    // 查询品牌故事或消息
    async queryStoryOrMessage() {
      this.tableData = [];
      let obj = {
        pagenum: this.pno,
        pagesize: 10,
      };
      let result = await storyOrMessage(obj);
      this.tableData = result.data.data;
      if (this.tableData.length < 10) {
        this.dataCount = false;
      } else if (this.tableData.length == 10) {
        this.dataCount = true;
      } else if (this.tableData.length == 0) {
        this.dataCount = false;
      }
      this.loading = false;
      this.tableData.map((item) => {
        if (item.top == "0") {
          item.top = false;
        } else if (item.top == "1") {
          item.top = true;
        }
        if (item.isactive == "0") {
          item.isactive = false;
        } else if (item.isactive == "1") {
          item.isactive = true;
        }
        if (item.type == "1") {
          item.type = this.$i18n.t("label.service.miniProgram.store");
        } else if (item.type == "2") {
          item.type = this.$i18n.t("chatter.news"); //消息
        }
      });
    },
    confirmDel() {
      if (this.viewType == "message") {
        this.deleteData();
      } else {
        // 删除轮播图
        this.deleCarousel();
      }
      this.dialogVisible = false;
    },
    getMoreData(type) {
      if (this.viewType == "message") {
        if (type == "top") {
          if (this.pno == 1) {
            return;
          } else {
            this.pno = this.pno - 1;
            this.loading = true;
            this.queryStoryOrMessage();
          }
        } else if (type == "next") {
          if (this.dataCount) {
            this.pno = this.pno + 1;
            this.loading = true;
            this.queryStoryOrMessage();
          }
        }
      } else {
        if (type == "top") {
          if (this.pno == 1) {
            return;
          } else {
            this.pno = this.pno - 1;
            this.loading = true;
            this.getCarouselList();
          }
        } else if (type == "next") {
          if (this.dataCount) {
            this.pno = this.pno + 1;
            this.loading = true;
            this.getCarouselList();
          }
        }
      }
    },
    deleteInfo(index, item) {
      this.messageId = item._id;
      this.dialogVisible = true;
    },
    addInfo(type, data) {
      if (type == "add" && this.carouselCount == 20) {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.service.miniProgram.quantity"),
        });
        return;
      }
      let id = "";
      if (data._id) {
        id = data._id;
      } else {
        id = "add";
      }
      if (this.viewType == "message") {
        this.$router.push(`editMessage/${type}/${id}`);
      } else if (this.viewType == "slideShow") {
        this.$router.push(`editSlideShow/${type}/${id}`);
      }
    },
  },
  components: {},
  computed: {
    topPage() {
      if (this.pno == 1) {
        return true;
      } else {
        return false;
      }
    },
    nextPage() {
      if (this.dataCount) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btnStyle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.button {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  color: #006dcc;
  padding: 5px 30px;
  font-size: 14px;
}
.button:focus {
  outline: none;
  border: 1px solid #dcdcdc;
}
::v-deep .el-table thead {
  color: #333333;
}
::v-deep .el-table {
  font-size: 14px;
  border-radius: 3px;
}

// ::v-deep .el-table td,
// .el-table th {
//   padding: 8px 0;
//   overflow-x: hidden;
// }
::v-deep .el-table .cell {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.allowClick {
  color: #006dcc;
  text-decoration: solid;
  cursor: pointer;
}
a {
  color: #000;
  cursor: auto;
}
::v-deep .allowClick.is-leaf {
  color: #3e3e3c;
  cursor: auto;
}
.btnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}
::v-deep .el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #dcdcdc;
}
::v-deep .el-table--border,
.el-table--group {
  border-color: #dcdcdc;
}
::v-deep .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #dcdcdc;
}
::v-deep .el-table--border th,
.el-table--border th.gutter:last-of-type {
  border-bottom: 1px solid #dcdcdc;
}
::v-deep .el-table--border td,
.el-table--border th {
  border-right: 1px solid #dcdcdc;
}
::v-deep .el-button {
  line-height: 0.5;
  padding: 15px 30px;
}
</style>